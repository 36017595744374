import React from 'react';
import syd from './img/Sydney.jpg';
import mlb from './img/Melbourne.jpg';
import adl from './img/Adelaide.jpg';
import bri from './img/Brisbane.jpg';
import per from './img/Perth.jpg';

function LandingPage(props) {
    return (
        <div>
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img src="images/APM-1.png" width="150px" alt=""/>
                        <h3 className="h3Title">Online Sabha Link</h3>
                        <div className="online">
                            <a className="onlineLink" href="http://live.bapsanz.org" target="_blank" rel="noopener noreferrer">http://live.bapsanz.org</a>
                        </div>
                        <h3 className="h3Title">Online Sabha Timings</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <div className="text-center sabha-sec">
                            <h4>Bal - Balika Sabha</h4>
                            <div className="online">Thursday 7:30 to 8:30pm</div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <div className="text-center sabha-sec">
                            <h4>Sanyukt Sabha</h4>
                            <div className="online">Sunday 5:00 to 7:00pm</div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
            <hr/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h3 className="h3Title">Daily Murti Darshan</h3>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4" onClick={() => {
                        props.history.push('/c/adl')
                    }}>
                        <div className="recent-work-wrap">
                            <img className="img-responsive img-radius" src={adl} alt=""/>
                            <div className="bottom-left overlay">
                                <div className="recent-work-inner">
                                    <h3>Adelaide</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-4" onClick={() => {
                        props.history.push('/c/bri')
                    }}>
                        <div className="recent-work-wrap">
                            <img className="img-responsive img-radius" src={bri} alt=""/>
                            <div className="bottom-left overlay">
                                <div className="recent-work-inner">
                                    <h3>Brisbane</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-4" onClick={() => {
                        props.history.push('/c/mel')
                    }}>
                        <div className="recent-work-wrap">
                            <img className="img-responsive img-radius" src={mlb} alt=""/>
                            <div className="bottom-left overlay">
                                <div className="recent-work-inner">
                                    <h3>Melbourne</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-4" onClick={() => {
                        props.history.push('/c/per')
                    }}>
                        <div className="recent-work-wrap">
                            <img className="img-responsive img-radius" src={per} alt=""/>
                            <div className="bottom-left overlay">
                                <div className="recent-work-inner">
                                    <h3>Perth</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-4" onClick={() => {
                        props.history.push('/c/syd')
                    }}>
                        <div className="recent-work-wrap">
                            <img className="img-responsive img-radius" src={syd} alt=""/>
                            <div className="bottom-left overlay">
                                <div className="recent-work-inner">
                                    <h3>Sydney</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
