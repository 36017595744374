import React from 'react';
import axios from 'axios';
import moment from 'moment';

const CITY_MAP = {
    'syd': 'Sydney',
    'mel': 'Melbourne',
    'adl': 'Adelaide',
    'bri': 'Brisbane',
    'per': 'Perth'
};


class CentrePage extends React.Component {
    constructor(props) {
        super(props);
        this.setRoi = this.setRoi.bind(this);
        this.loadContent = this.loadContent.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.hideLoading = this.hideLoading.bind(this);

        const days = 5;
        const results = [];

        for(let x = 0; x < days; x++) {
            const td = moment().subtract(x, 'days');
            const day = td.format('YYYY-MM-DD');
            const dday = td.format('DD-MM-YYYY');
            const ro = {
                day: day,
                dday: dday,
                yt: { e: false },
                mp4: {},
                img: [
                  { k: '1.jpeg' },
                  { k: '2.jpeg' },
                  { k: '3.jpeg' },
                  { k: '4.jpeg' },
                  { k: '5.jpeg' }
                ]
            };
            results.push(ro);
        }

        this.state = {
            loading: true,
            url: `/md/${this.props.match.params.city}`,
            results: results
        }
    }

    componentDidMount() {
        this.loadContent(0);
        this.hideLoading();
    }

    updateYT = (roi, data) => {
        const newState = Object.assign({}, this.state);
        newState.results[roi].yt = data;
        this.setState(newState);
    };

    loadYVideo = (roi, result) => {
        if(!result.YV) {
            axios.get(`${this.state.url}/${result.day}/V.json`)
                .then(r => {
                    this.updateYT(roi, { data: r.data, e: true });
                })
                .catch(e => {
                    this.contentError(roi);
                })
        }
    };

    loadContent = (roi) => {
        const newState = Object.assign({}, this.state);
        newState.loading = false;
        newState.roi = roi;
        console.log(newState);
        this.loadYVideo(roi, newState.results[roi]);
        this.setState(newState);
    };

    showLoading = () => {
        const newState = Object.assign({}, this.state);
        newState.loading = true;
        this.setState(newState);
    };

    hideLoading = () => {
        const newState = Object.assign({}, this.state);
        newState.loading = false;
        this.setState(newState);
    };

    setRoi = (change) => {
        this.showLoading();
        this.loadContent(this.state.roi + change);
    };

    contentLoaded = (roi, i) => {
        const newState = Object.assign({}, this.state);
        newState.results[roi].img[i].e = true;
        this.setState(newState);
    };

    contentError = (roi) => {
        const newState = Object.assign({}, this.state);
        const errCount = newState.results[roi].error || 0;
        newState.results[roi].error = errCount + 1;
        this.setState(newState);
    };

    render() {
        if(this.state.loading)
            return Loading();
        else {
            return (
                <div className="container">
                    <br />
                    { this.state.results.map((ro, roi, arr) => {
                        if(roi !== this.state.roi) return (<div key={roi}></div>)

                        return (
                            <div key={roi}>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h3 className="h3Title">{CITY_MAP[this.props.match.params.city]}</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="text-center sabha-sec">
                                            <h4>{ro.dday}</h4>
                                        </div>
                                    </div>
                                </div>
                                <Nav roi={this.setRoi} prev={roi < (arr.length - 1)} next={roi > 0} />

                                {ro.error >= 6 && (<div className="alert alert-warning text-center" role="alert">
                                    No darshan available for this day.
                                </div>)}

                                <Imgs {...ro} roi={roi} centre={this.props.match.params.city} contentLoaded={this.contentLoaded} contentError={this.contentError} />
                                <Vid {...ro} centre={this.props.match.params.city} roi={roi} contentLoaded={this.contentLoaded} contentError={this.contentError} />
                                <YTVideo yt={ro.yt}/>
                            </div>)
                    }) }
                </div>
            );
        }
    }
}

function YTVideo(props) {
    if(!props.yt.e) return (<div/>);

    return (<div className="row">
        <div className="col">
            <div className="iv embed-responsive embed-responsive-16by9">
                <iframe
                    title="video"
                    className={`img img-fluid iv ${props.yt.e ? '' : 'd-none'}`}
                    src={`https://www.youtube.com/embed/${props.yt.data.yid}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </div>
        </div>
    </div>);
}

function Img(props) {
    return (<div className="col-md-4">
        <a href={`/md/${props.centre}/${props.day}/${props.k}`} target="_blank"
           rel="noopener noreferrer">
            <img src={`/md/${props.centre}/${props.day}/${props.k}`}
                 className={`img-fluid img ${props.e ? '' : 'd-none'}`}
                 alt=""
                 onLoad={() => props.contentLoaded(props.roi, props.i)}
                 onError={() => { props.contentError(props.roi) }}
            />
        </a>
    </div>);
}

function Imgs(props) {
    return (<div className="row">{
        props.img.filter(p => p.k !== 'V.mp4').map((p, i) => {
            return <Img {...p} i={i} {...props} key={p.k} />
        })}
    </div>);
}

function Loading() {
    return (
        <div className="container">
            <br />
            <div className="alert alert-info" role = "alert" >Loading...</div>
        </div>
    );
}

function Nav(props) {
    return (
      <div className="row">
          <div className="col text-center">
              <nav>
                  <ul className="pagination">
                      <li className={`page-item ${props.prev ? "" : "disabled"}`} >
                          <span onClick={() => props.roi(1)} className="page-link">Previous</span>
                      </li>
                      <li className={`page-item ${props.next ? "" : "disabled"}`} >
                          <span onClick={() => props.roi(-1)} className="page-link">Next</span>
                      </li>
                  </ul>
              </nav>
          </div>
      </div>
    );
}

function Vid(props) {
    return (<div className="row">{
        props.img.filter(p => p.k === 'V.mp4').map((p, i) => {
            return (<div className="text-center" key={p}>
                <video className={`img-fluid iv ${p.e ? '' : 'd-none'}`} controls
                       onLoadedData={() => { props.contentLoaded(props.roi, 5) }}
                       onError={() => { props.contentError(props.roi) }} >
                    <source src={`/md/${props.centre}/${props.day}/${p.k}`} type="video/mp4" />
                    Your browser does not support the video.
                </video>
            </div>)
        })}
    </div>);
}

export default CentrePage;
