import React from 'react';
import baps_logo from './baps_logo.svg';
import LandingPage from './landingPage.js';
import CentrePage from './centrePage.js';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import withTracker from "./withTracker";

function App() {
    return (
        <div>
            <div className="heading">
                <img src={baps_logo} className="device-img" alt="BAPS"/>
            </div>
            <main>
                <Switch>
                    <Route exact path="/" component={withTracker(LandingPage)} />
                    <Route exact path="/c/:city" component={withTracker(CentrePage)} />
                </Switch>
            </main>
        </div>
    );
}

export default App;
